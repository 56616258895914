import React, { useEffect } from "react";
import "./AboutBanner.css";
import SectionHeading from "../../Reusable/SectionHeading/SectionHeading";
import aboutBannerImg from "../../../assests/aboutBanner.svg";
import logoInBox from "../../../assests/LogoInBox.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const AboutBanner = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <div className="AboutBanner">
        <div data-aos="fade-up" className="talentDiscovery marginTop65" >
          <SectionHeading
            highLight="Hirein5 "
            head1="is streamlining "
            head2="talent discovery"
            headClass="HeadFont55"
            desc1="Changing the way most of the hiring is done, till now!."
            desc2=""
            desc3=""
            descMob="Changing the way most of the hiring is done, till now!."
          />
          <div className="talentDiscoveryImg">
            <img className="aboutBannerImg" src={aboutBannerImg} alt="" />
            <img
              data-aos="zoom-in"
              className="logoInBox"
              src={logoInBox}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutBanner;

import React from "react";
import "./WorkBanner.css";
import contactLeft from "../../../assests/PricingBannerLeft.svg";
import contactRight from "../../../assests/PricingBannerRight.svg";
import { useNavigate } from "react-router-dom";

const WorkBanner = () => {
  const navigate = useNavigate();
  return (
    <div className="">
      <div className="contactBanner marginTop30">
        <img className="webHandler" src={contactLeft} alt="" />
        <div className="contactBannerContent">
          <h1>Empowering your Hiring Journey</h1>
          <p>Let us help you in making your hiring journey frictionless</p>
          <button onClick={() => navigate("/contact")} className="signButton moveButton">
            Contact Us
          </button>
        </div>
        <img className="webHandler" src={contactRight} alt="" />
      </div>
    </div>
  );
};

export default WorkBanner;

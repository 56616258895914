/* eslint-disable eqeqeq */
import React, { useState } from "react";
import SectionHeading from "../SectionHeading/SectionHeading";
import FaqCard from "../FaqCard/FaqCard";
import { useLocation } from "react-router-dom";

const Faq = () => {
  const location = useLocation();
  const [isShow, setIsShow] = useState("6");
  const faqHandler = (e) => {
    setIsShow(e.target.id);
  };
  const faqData = {
    "/pricing": [
      {
        number: "6",
        head: "Why do you charge an upfront fee during sign up?",
        desc: "Our unique set up and platform provides access to pre vetted talent who can be on-boarded in a matter of hours. We believe that this creates significant value for businesses, and saves significant time and cost for the recruiters and the business. Our on-boarding fee is nominal in comparison to the value it creates.",
      },
      {
        number: "7",
        head: "Why do you charge an Annual or Monthly access fee over and above the initial set up fee?",
        desc: "We add new talent and resources each month on our platform and would like our clients to benefit from the continuous improvements that we are making. Our subscription fee ensures that you continue to have access to new skills and talent.",
      },
      {
        number: "8",
        head: "Can you waive the initial set up fees in lieu of the monthly subscription?",
        desc: "The value that Hirein5 generates is of significance and saves time, effort and money, while accessing amazing talent at the click of a button. Our pricing is well placed to provide continuous value for the team.",
      },
      {
        number: "9",
        head: "Are the resource prices fixed?",
        desc: "We carefully evaluate our talent’s skills and fix prices accordingly. Our talent is of high quality and can add significant value to any project. Our prices are therefore transparent and published on the platform against each candidate.",
      },
      {
        number: "10",
        head: "Where can I find all the contracts that I sign with Hirein5?",
        desc: "Every contract that you sign is available under your account on the platform for ease of access and reference.",
      },
      {
        number: "11",
        head: "Is Hirein5 suitable for businesses of all sizes?",
        desc: "Yes, Hirein5 is designed to cater to businesses of all sizes, including startups, small and medium-sized enterprises (SMEs), as well as large corporations. Our platform is scalable and adaptable to the needs of companies regardless of their size.",
      },
      {
        number: "12",
        head: "Are there any additional fees or hidden costs that I should be aware of?",
        desc: "Transparency is important to us, and we strive to ensure that our pricing is clear and upfront. There are no hidden costs or surprise fees – what you see is what you get.",
      },
      {
        number: "13",
        head: "What happens if I need to scale up or down my team's resources?",
        desc: "Our offering is flexible to accommodate your changing needs. You can easily adjust your team size by adding or removing resources as provisioned in the contract, ensuring you have the right talent for your team at all times.",
      },
    ],
    "/": [
      {
        number: "6",
        head: "What is Hirein5?",
        desc: "Hirein5 is a platform that has integrated all stages of hiring from Search to Offer in one platform, thereby making it easy and efficient for the recruiters and employers to hire talent.",
      },
      {
        number: "7",
        head: "How does Hirein5 Work?",
        desc: "Hirein5 revolutionises C2C hiring, slashing weeks of recruitment anxiety and costs. Our platform stands out with pre-vetted resources, not just technically assessed but also evaluated for personality, aptitude and background verification, ensuring swift, confident hiring decisions. Clients can directly evaluate candidates through their video resumes, providing a holistic view of their qualifications and fitment for the role.",
      },
      {
        number: "8",
        head: "How is Hirein5 different from other platforms? ",
        desc: "Hirein5 is not a job or an interview portal. It is an integrated end to end hiring platform for C2C engagements. Hirein5 is investing heavily in automating the process even further using reliable AI components.",
      },
      {
        number: "9",
        head: "Can Hirein5 really fulfil the requirement in under 5 hours?",
        desc: "Process efficiency by the hiring team is the key in meeting the timelines. Hirein5 has enabled a standard hiring process laid down by most companies, from online selection, assessment, interviews and contractual signatures.",
      },
      {
        number: "10",
        head: "What happens if the resource we hire does meet our requirements?",
        desc: "The contract has clear provisions for such rare occurrences where there may be a mismatch between the technical requirements and the resources who have been signed up.",
      },
      {
        number: "11",
        head: "Is Hirein5 suitable for businesses of all sizes?",
        desc: "Yes, Hirein5 is designed to cater to businesses of all sizes, including startups, small and medium-sized enterprises (SMEs), as well as large corporations. Our platform is scalable and adaptable to the needs of companies regardless of their size.",
      },
      {
        number: "12",
        head: "What industries or sectors does Hirein5 specialise in?",
        desc: "Hirein5 is versatile and can be utilised across various industries , sectors and domains.",
      },
      {
        number: "13",
        head: "Can clients track the progress of their hiring process on Hirein5?",
        desc: "Yes, clients have full visibility into the progress of their hiring process on Hirein5. Our platform provides real-time tracking and monitoring capabilities, allowing clients to stay informed about the status of each stage, from candidate sourcing to offer acceptance.",
      },
  ],
  '/how-hirein5-works':[
    {
      number: "6",
      head: "Is each resource on the platform a Hirein5 employee?",
      desc: "Every Talent on board Hirein5 has been carefully vetted via a series of assessments You will sign a contract with Hirein5 for each resource hired via the platform.",
    },
    {
      number: "7",
      head: "How many resources can I hire on the platform?",
      desc: "As many as you need. There is no restriction on how many resources you can hire.",
    },
    {
      number: "8",
      head: "What kind of engagement models do you offer?",
      desc: "Our flexible engagement model allows for the quickest hiring via our remote C2C and C2H staffing engagement model for which the rates are pre-published. For other engagements, like on-site staffing or direct recruitment, please connect with our sales team or your account manager. ",
    },
    {
      number: "9",
      head: "I am looking for skills which are not available on your portal.",
      desc: "We are onboarding new skills all the time and it may be possible that a specific skill may not be readily available. Please reach out to us for any skill that you may need to hire.",
    },
    {
      number: "10",
      head: "We have a different interview process. Can the interview process be altered on the platform?",
      desc: "We do not offer customization at this point in time. Most companies require no more than 2 interviews to make a decision, which our platform already provisions for. ",
    },
    {
      number: "11",
      head: "We would like to administer additional tests to the candidates. Can you enable that for us?",
      desc: "We will be happy to assist in getting additional tests done outside the platform. Please reach out to us to enable this for you. The charges for additional tests may vary based on the recommendation for each test, and will be notified and charged back. ",
    },
    {
      number: "12",
      head: "We want the resources to be placed in our office on-site. How soon can this happen?",
      desc: "At this point, our on-site placement is restricted to a few countries. We are enabling more countries in the future. The timelines will depend on various factors beyond our control but we aim to place the resource in less than 2 months, in most cases. You may start the engagement with the resources remotely while waiting for the on-site placement to commence. ",
    },
    {
      number: "13",
      head: "Can we get access to the platform without a contract?",
      desc: "We have a demo instance of the platform that you may request access to via our sales team. Once you have signed the contract, you will be granted access to the live platform.",
    },
    {
      number: "14",
      head: "Could we hire a resource directly on our payroll?",
      desc: "With our Contract-to-Contract (C2C) model, you can evaluate a candidate's skills remotely before extending a permanent offer. Our contracts may provision for such a transfer to happen post completion of 6 months of the remote contract.",
    },
    {
      number: "15",
      head: "How do you ensure the quality and reliability of the talent on your platform?",
      desc: "We have stringent vetting processes in place to evaluate the skills and expertise of all talent on our platform. Additionally, we regularly monitor performance and gather feedback to maintain high standards of quality and reliability.",
    },
    {
      number: "16",
      head: "What support and resources do you provide to help maximise the effectiveness of our team?",
      desc: "Alongside access to top talent, we offer ongoing support and resources to help you get the most out of your team. This includes training opportunities, performance management tools, and dedicated account managers to address any concerns or challenges you may encounter.",
    },
  ]
  };
  const currentFaqs = faqData[location.pathname] || [];
  return (
    <div>
      <div className="faq">
        <div className="faqHead mainbody">
          <SectionHeading
            head1="Frequently Asked Questions"
            head2=""
            desc1=""
            desc2=""
            desc3=""
          />
        </div>
        {/* {location.pathname == "/pricing" ? (
          <div className="faqBody">
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="6"
              numClass="numberNone"
              head="Why do you charge an upfront fee during sign up?"
              headClass="headBold"
              desc="Our unique set up and platform provides access to pre vetted talent who can be on-boarded in a matter of hours. We believe that this creates significant value for businesses, and saves significant time and cost for the recruiters and the business. Our on-boarding fee is nominal in comparison to the value it creates."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="7"
              numClass="numberNone"
              head="Why do you charge an Annual or Monthly access fee over and above the initial set up fee?"
              headClass="headBold"
              desc="We add new talent and resources each month on our platform and would like our clients to benefit from the continuous improvements that we are making. Our subscription fee ensures that you continue to have access to new skills and talent."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="8"
              numClass="numberNone"
              head="Can you waive the initial set up fees in lieu of the monthly subscription?"
              headClass="headBold"
              desc="The value that Hirein5 generates is of significance and saves time, effort and money, 
              while accessing amazing talent at the click of a button. Our pricing is well placed to provide 
              continuous value for the team."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="9"
              numClass="numberNone"
              head="Are the resource prices fixed?"
              headClass="headBold"
              desc="We carefully evaluate our talent’s skills and fix prices accordingly. Our talent is of high quality and can add significant value to any project. Our prices are therefore transparent and published on the platform against each candidate."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="10"
              numClass="numberNone"
              head="Where can I find all the contracts that I sign with Hirein5?"
              headClass="headBold"
              desc="Every contract that you sign is available under your account on the platform for ease of access and reference."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="11"
              numClass="numberNone"
              head="Is Hirein5 suitable for businesses of all sizes?"
              headClass="headBold"
              desc="Yes, Hirein5 is designed to cater to businesses of all sizes, including startups, small and medium-sized enterprises (SMEs), as well as large corporations. Our platform is scalable and adaptable to the needs of companies regardless of their size."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="12"
              numClass="numberNone"
              head="Are there any additional fees or hidden costs that I should be aware of?"
              headClass="headBold"
              desc="Transparency is important to us, and we strive to ensure that our pricing is clear and upfront. There are no hidden costs or surprise fees – what you see is what you get."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="13"
              numClass="numberNone"
              head="What happens if I need to scale up or down my team's resources?"
              headClass="headBold"
              desc="Our offering is flexible to accommodate your changing needs. You can easily adjust your team size by adding or removing resources as provisioned in the contract, ensuring you have the right talent for your team at all times."
            />
          </div>
        ) : location.pathname == "/" ? (
          <div className="faqBody">
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="6"
              numClass="numberNone"
              head="What is Hirein5?"
              headClass="headBold"
              desc="Hirein5 is a platform that has integrated all stages of hiring from Search to Offer in one platform, thereby making it easy and efficient for the recruiters and employers to hire talent."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="7"
              numClass="numberNone"
              head="How does Hirein5 Work?"
              headClass="headBold"
              desc="Hirein5 revolutionises C2C hiring, slashing weeks of recruitment anxiety and costs. Our platform stands out with pre-vetted resources, not just technically assessed but also evaluated for personality, aptitude and background verification, ensuring swift, confident hiring decisions. Clients can directly evaluate candidates through their video resumes, providing a holistic view of their qualifications and fitment for the role."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="8"
              numClass="numberNone"
              head="How is Hirein5 different from other platforms?"
              headClass="headBold"
              desc="Hirein5 is not a job or an interview portal. It is an integrated end to end hiring platform for C2C engagements. Hirein5 is investing heavily in automating the process even further using reliable AI components."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="9"
              numClass="numberNone"
              head="Can Hirein5 really fulfil the requirement in under 5 hours?"
              headClass="headBold"
              desc="Process efficiency by the hiring team is the key in meeting the timelines. Hirein5 has enabled a standard hiring process laid down by most companies, from online selection, assessment, interviews and contractual signatures."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="10"
              numClass="numberNone"
              head="What happens if the resource we hire does meet our requirements?"
              headClass="headBold"
              desc="The contract has clear provisions for such rare occurrences where there may be a mismatch between the technical requirements and the resources who have been signed up."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="11"
              numClass="numberNone"
              head="Is Hirein5 suitable for businesses of all sizes?"
              headClass="headBold"
              desc="Yes, Hirein5 is designed to cater to businesses of all sizes, including startups, small and medium-sized enterprises (SMEs), as well as large corporations. Our platform is scalable and adaptable to the needs of companies regardless of their size."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="12"
              numClass="numberNone"
              head="What industries or sectors does Hirein5 specialise in?"
              headClass="headBold"
              desc="Hirein5 is versatile and can be utilised across various industries , sectors and domains."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="13"
              numClass="numberNone"
              head="Can clients track the progress of their hiring process on Hirein5?"
              headClass="headBold"
              desc="Yes, clients have full visibility into the progress of their hiring process on Hirein5. Our platform provides real-time tracking and monitoring capabilities, allowing clients to stay informed about the status of each stage, from candidate sourcing to offer acceptance."
            />
          </div>
        ) : (
          <div className="faqBody">
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="6"
              numClass="numberNone"
              head="What is Hirein5?"
              headClass="headBold"
              desc="Hirein5 is a platform that has integrated all stages of hiring from Search to Offer in one platform, thereby making it easy and efficient for the recruiters and employers to hire talent."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="7"
              numClass="numberNone"
              head="How does Hirein5 Work?"
              headClass="headBold"
              desc="Hirein5 revolutionises C2C hiring, slashing weeks of recruitment anxiety and costs. Our platform stands out with pre-vetted resources, not just technically assessed but also evaluated for personality, aptitude and background verification, ensuring swift, confident hiring decisions. Clients can directly evaluate candidates through their video resumes, providing a holistic view of their qualifications and fitment for the role."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="8"
              numClass="numberNone"
              head="How is Hirein5 different from other platforms? "
              headClass="headBold"
              desc="Hirein5 is not a job or an interview portal. It is an integrated end to end hiring platform for C2C engagements. Hirein5 is investing heavily in automating the process even further using reliable AI components."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="9"
              numClass="numberNone"
              head="Can Hirein5 really fulfil the requirement in under 5 hours?"
              headClass="headBold"
              desc="Process efficiency by the hiring team is the key in meeting the timelines. Hirein5 has enabled a standard hiring process laid down by most companies, from online selection, assessment, interviews and contractual signatures."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="10"
              numClass="numberNone"
              head="What happens if the resource we hire does meet our requirements?"
              headClass="headBold"
              desc="The contract has clear provisions for such rare occurrences where there may be a mismatch between the technical requirements and the resources who have been signed up."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="11"
              numClass="numberNone"
              head="Is Hirein5 suitable for businesses of all sizes?"
              headClass="headBold"
              desc="Yes, Hirein5 is designed to cater to businesses of all sizes, including startups, small and medium-sized enterprises (SMEs), as well as large corporations. Our platform is scalable and adaptable to the needs of companies regardless of their size."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="12"
              numClass="numberNone"
              head="What industries or sectors does Hirein5 specialise in?"
              headClass="headBold"
              desc="Hirein5 is versatile and can be utilised across various industries , sectors and domains."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="13"
              numClass="numberNone"
              head="Can clients track the progress of their hiring process on Hirein5?"
              headClass="headBold"
              desc="Yes, clients have full visibility into the progress of their hiring process on Hirein5. Our platform provides real-time tracking and monitoring capabilities, allowing clients to stay informed about the status of each stage, from candidate sourcing to offer acceptance."
            />
          </div>
        )} */}
         <div className="faqBody">
          {currentFaqs.map((faq, index) => (
            <FaqCard
              key={index}
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number={faq.number}
              numClass="numberNone"
              head={faq.head}
              headClass="headBold"
              desc={faq.desc}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;

import React from "react";
import "./Footer.css";
import logoBlack from "../../assests/logoBlack.svg";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footer sectionWidth">
      <div className="footerContent">
        <div className="footerImg">
          <img src={logoBlack} alt="" />
        </div>
        <div className="footerMenu">
          <div className="siteMap">
            <h1>Sitemap</h1>
            <a href="/">
              <h2 onClick={() => navigate("/")}>Home</h2>
            </a>
            <a href="/about-hirein5">
              <h2 onClick={() => navigate("/about-hirein5")}>About Us</h2>
            </a>
            <a href="/how-hirein5-works">
              <h2 onClick={() => navigate("/how-hirein5-works")}>
                How it Works
              </h2>
            </a>
            <a href="/talent">
              <h2 onClick={() => navigate("/talent")}>For Talent</h2>
            </a>
            {/* <a href="/pricing">
              <h2 onClick={() => navigate("/pricing")}>Pricing</h2>
            </a> */}
            <a href="/contact">
              <h2 onClick={() => navigate("/contact")}>Contact Us</h2>
            </a>
            {/* <h2 onClick={() => navigate("/resource")}>Resources</h2> */}
          </div>
          <div className="resources">
            <h1>Resources</h1>
            <a
              href="https://app.termly.io/policy-viewer/policy.html?policyUUID=ff795be0-1ecd-4ef8-b9ac-6ee47dc8b29a"
              target="_blank"
            >
              <h2>Privacy Policy</h2>
            </a>
            <a
              href="https://app.termly.io/policy-viewer/policy.html?policyUUID=d24970fd-cbb1-45fd-990b-b6fbb3f82dbb"
              target="_blank"
            >
              <h2>T&C</h2>
            </a>
          </div>
        </div>
      </div>
      <p className="copyright">
        ©2024 Hirein5. All rights reserved. A unit of Turnaround Tree Global
        Solutions Pvt. Ltd.
      </p>
    </div>
  );
};

export default Footer;

/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable eqeqeq */
import "./App.css";
import Routing from "./Routing/Routing";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "./Store/Store";
import { InlineWidget } from "react-calendly";

function App() {
  const dispatch = useDispatch();
  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });

  const closeOverlay = () => {
    dispatch(storeAction.isPopUpHander());
  };

  return (
    <div className="App">
      <Routing />
      {isPopUp == "applynow" || isPopUp == "demo" || isPopUp == "mobileNav" ? (
        <div onClick={closeOverlay} id="overlay"></div>
      ) : null}
      {isPopUp == "demo" && (
        <div className="Demo overlay">
          {/* <iframe
            width="100%"
            height="750px"
            src="https://hirein51.zohobookings.in/portal-embed#/221623000000029054"
            frameborder="0"
            allowfullscreen=""
          ></iframe> */}
          <div className="calendly">
            <InlineWidget url="https://calendly.com/hirein5" />
          </div>
          <button
            onClick={closeOverlay}
            className="signButton marginLeft30 demoButtonwidth"
          >
            Back to Website
          </button>
        </div>
      )}
    </div>
  );
}

export default App;

import React from "react";
import "./CarrierSuccess.css";
import SectionHeading from "../../Reusable/SectionHeading/SectionHeading";
import CarrierSuccessCard from "../../Reusable/CarrierSuccessCard/CarrierSuccessCard";
import star from "../../../assests/star.svg";
import polo from "../../../assests/polo.svg";
import triangle from "../../../assests/triangle.svg";

const CarrierSuccess = () => {
  return (
    <div>
      <div className="carrierSuccessConatiner">
        <SectionHeading
          head1="Enabling your career success"
          head2=""
          desc1="Be part of hiring revolution by joining Hirein5. 
          Everything at Hirein5 is built to maximize your career velocity 
          and provide you the best opportunities. 
          How does it work?"
          desc2=""
          desc3=""
          desc4=""
          descMob="Be part of hiring revolution by joining Hirein5. 
          Everything at Hirein5 is built to maximize your career velocity 
          and provide you the best opportunities. 
          How does it work?"
        />
        <div className="carrierSuccessConatinerCard">
          <CarrierSuccessCard
            cardClass="carrierSuccessCard carrierSuccessCardOrange"
            image={star}
            head1="Apply in one click"
            desc1="Share your latest resume for us to connect with you to find suitable opportunities, from anywhere in the world."
            desc2=""
            desc3=""
          />
          <CarrierSuccessCard
            cardClass="carrierSuccessCard carrierSuccessCardBlack"
            image={polo}
            head1="Complete your profile"
            head2="openings"
            desc1="Our Candidate Application portal allows you to input details enriching your profile to find suitable openings. "
            desc2=""
            desc3=""
          />
          <CarrierSuccessCard
            cardClass="carrierSuccessCard carrierSuccessCardGreen"
            image={triangle}
            head1="Your next big move"
            head2="and job offer"
            desc1="Our Global clients scout for great talent like you all the time. Hirein5 creates opportunities for international placement. "
            desc2=""
            desc3=""
          />
        </div>
        <div className="hiringPlatformFlexCol">
          <div className="carrierSuccessConatinerCardTab">
            <CarrierSuccessCard
              cardClass="carrierSuccessCard carrierSuccessCardOrange"
              image={star}
              head1="Apply in one click"
              desc1="Share your latest resume for us to connect with you to find suitable opportunities, from anywhere in the world."
              desc2=""
              desc3=""
            />
            <CarrierSuccessCard
              cardClass="carrierSuccessCard carrierSuccessCardBlack"
              image={polo}
              head1="Complete your profile openings"
              head2=""
              desc1="Our Candidate Application portal allows you to input details enriching your profile to find suitable openings. "
              desc2=""
              desc3=""
            />
            <CarrierSuccessCard
              cardClass="carrierSuccessCard carrierSuccessCardGreen"
              image={triangle}
              head1="Your next big move and job offer"
              head2=""
              desc1="Our Global clients scout for great talent like you all the time. Hirein5 creates opportunities for international placement. "
              desc2=""
              desc3=""
            />
          </div>
          <div>
            <button className="loginButtonReq bgWhite">
              <a
                href="https://app.hirein5.com/#/candidateregister"
                target="_blank"
              >
                Apply Now
              </a>
            </button>
                  {/* <a
                id="signupBtn"
                className="signButton"
                href="https://app.hirein5.com"
                target="_blank"
              >
              Sign Up
              </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarrierSuccess;

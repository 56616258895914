import React from "react";
import "./ContactBanner.css";
import contactLeft from "../../../assests/PricingBannerLeft.svg";
import contactRight from "../../../assests/PricingBannerRight.svg";
import { useDispatch } from "react-redux";
import { storeAction } from "../../../Store/Store";

const ContactBanner = () => {
  const dispatch = useDispatch();

  const demoHandler = () => {
    dispatch(storeAction.isPopUpHander("demo"));
  };
  return (
    <div>
      <div className="contactBanner">
        <img className="webHandler" src={contactLeft} alt="" />
        <div className="contactBannerContent">
          <h1>We’d love to hear from you</h1>
          <button onClick={demoHandler} className="">
            Book a Demo
          </button>
          <p>Or please fill the form</p>
        </div>
        <img className="webHandler" src={contactRight} alt="" />
      </div>
    </div>
  );
};

export default ContactBanner;

import React from "react";
import "./PercentCard.css";
import { FaArrowRight } from "react-icons/fa6";

const PercentCard = (props) => {
  return (
    <div>
      <div className="percentCard">
        <div className="percentCardMob">
          <h1>{props.percent}</h1>
          <h2>{props.percentDesc}</h2>
        </div>
        <h3>{props.Head}</h3>
        <h4>{props.content}</h4>
        <h5 className={props.btnClass} onClick={props.func}>
          {props.button} <FaArrowRight className={props.IconClass} />
        </h5>
      </div>
    </div>
  );
};

export default PercentCard;

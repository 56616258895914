import React, { useState } from "react";
import "./ContactForm.css";
import axios from "axios";
import Countrycode from "./CountryCodes.json";

const ContactForm = () => {
  const [formdata, setformdata] = useState({
    firstname: "",
    lastname: "",
    company_name: "",
    company_email: "",
    title: "",
    country: "",
    industry: "",
    phone: "",
    message: "",
  });
  const [formdataerror, setformdataerror] = useState({
    firstname: false,
    lastname: false,
    company_name: false,
    company_email: false,
    title: false,
    country: false,
    industry: false,
    phone: false,
    message: false,
  });
  const [checkbox, setcheckbox] = useState(false);
  const [checkboxerror, setcheckboxerror] = useState(false);
  const [disable, setdisable] = useState(false);
  const [successstatus, setsuccessstatus] = useState(false);

  const handlechange = (e) => {
    const { name, value } = e.target;
    setformdata((values) => ({ ...values, [name]: value }));
  };
  const submitbtn = async () => {
    setformdataerror({
      firstname: false,
      lastname: false,
      company_name: false,
      company_email: false,
      title: false,
      country: false,
      industry: false,
      phone: false,
      message: false,
    });
    setcheckboxerror(false);
    if (formdata.firstname.length == 0) {
      setformdataerror((values) => ({
        ...values,
        firstname: true,
      }));
    } else if (formdata.lastname.length == 0) {
      setformdataerror((values) => ({
        ...values,
        lastname: true,
      }));
    } else if (formdata.company_name.length == 0) {
      setformdataerror((values) => ({
        ...values,
        company_name: true,
      }));
    } else if (formdata.company_email.length == 0) {
      setformdataerror((values) => ({
        ...values,
        company_email: true,
      }));
    } else if (formdata.title.length == 0) {
      setformdataerror((values) => ({
        ...values,
        title: true,
      }));
    } else if (formdata.country.length == 0) {
      setformdataerror((values) => ({
        ...values,
        country: true,
      }));
    } else if (formdata.industry.length == 0) {
      setformdataerror((values) => ({
        ...values,
        industry: true,
      }));
    } else if (formdata.phone.length == 0) {
      setformdataerror((values) => ({
        ...values,
        phone: true,
      }));
    } else if (formdata.message.length == 0) {
      setformdataerror((values) => ({
        ...values,
        message: true,
      }));
    } else if (checkbox == false) {
      setcheckboxerror(true);
    } else {
      setdisable(true);

      setformdataerror({
        firstname: false,
        lastname: false,
        company_name: false,
        company_email: false,
        title: false,
        country: false,
        industry: false,
        phone: false,
        message: false,
      });
      setcheckboxerror(false);
      var notification = {
        email: "sales@hirein5.com",
        subject: `Contact Form Submission`,
        message: `
        <p>A new message has been received through the contact form on the website. Please find the details below:</p>
        <p>Name : ${formdata.firstname} ${formdata.lastname}</p>
        <p>Company Name : ${formdata.company_name}</p>
        <p>Company Email : ${formdata.company_email}</p>
        <p>Job Title : ${formdata.title}</p>
        <p>Country : ${formdata.country}</p>
        <p>Industry : ${formdata.industry}</p>
        <p>Phone no : ${formdata.phone}</p>
        <p>Message : ${formdata.message}</p>
        <p>Please reach out if you need any further information or clarification.</p>
        `,
      };
      setTimeout(() => {
        setsuccessstatus(true);
      }, 2000);

      setTimeout(() => {
        window.location.reload();
      }, 8000);
      await axios
        .post(`https://api.hirein5.com/externalEmail/`, notification)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      setTimeout(() => {
        setdisable(false);
        setformdata({
          firstname: "",
          lastname: "",
          company_name: "",
          company_email: "",
          title: "",
          country: "",
          industry: "",
          phone: "",
          message: "",
        });
      }, 3000);
    }
  };
  return (
    <div>
      <div className="contactForm">
        <div className="contactFormBody bgWhite">
          <div className="contactFormFlex">
            <div className="contactFormContent">
              <h2>First Name</h2>
              <input
                className="contactFormContentInput"
                type="text"
                name="firstname"
                onChange={handlechange}
                defaultValue={formdata.firstname}
              />
              {formdataerror.firstname && (
                <h6 className="errormsg">Please Enter First Name</h6>
              )}
            </div>
            <div className="contactFormContent">
              <h2>Last Name</h2>
              <input
                className="contactFormContentInput"
                type="text"
                name="lastname"
                onChange={handlechange}
                defaultValue={formdata.lastname}
              />
              {formdataerror.lastname && (
                <h6 className="errormsg">Please Enter Last Name</h6>
              )}
            </div>
          </div>
          <div className="contactFormFlex">
            <div className="contactFormContent">
              <h2>Company Name</h2>
              <input
                className="contactFormContentInput"
                type="text"
                name="company_name"
                onChange={handlechange}
                defaultValue={formdata.company_name}
              />
              {formdataerror.company_name && (
                <h6 className="errormsg">Please Enter Company Name</h6>
              )}
            </div>
            <div className="contactFormContent">
              <h2>Company Email</h2>
              <input
                className="contactFormContentInput"
                type="text"
                name="company_email"
                onChange={handlechange}
                defaultValue={formdata.company_email}
              />
              {formdataerror.company_email && (
                <h6 className="errormsg">Please Enter Company Email</h6>
              )}
            </div>
          </div>
          <div className="contactFormFlex">
            <div className="contactFormContent">
              <h2>Job Title</h2>
              <input
                className="contactFormContentInput"
                type="text"
                name="title"
                onChange={handlechange}
                defaultValue={formdata.title}
              />
              {formdataerror.title && (
                <h6 className="errormsg">Please Enter Job Title</h6>
              )}
            </div>
            <div className="contactFormContent">
              <h2>Country</h2>
              <input
                className="contactFormContentInput"
                type="text"
                name="country"
                onChange={handlechange}
                defaultValue={formdata.country}
              />
              {formdataerror.country && (
                <h6 className="errormsg">Please Enter Country</h6>
              )}
            </div>
          </div>
          <div className="contactFormContent">
            <h2>Industry</h2>
            <select
              className="contactFormContentInput"
              type="text"
              name="industry"
              onChange={handlechange}
              defaultValue={formdata.industry}
            >
              <option value="">Select Industry</option>
              <option value="Agriculture">Agriculture</option>
              <option value="Apparel">Apparel</option>
              <option value="Banking">Banking</option>
              <option value="Biotechnology">Biotechnology</option>
              <option value="Chemicals">Chemicals</option>
              <option value="Communications">Communications</option>
              <option value="Construction">Construction</option>
              <option value="Consulting">Consulting</option>
              <option value="Education">Education</option>
              <option value="Electronics">Electronics</option>
              <option value="Energy">Energy</option>
              <option value="Engineering">Engineering</option>
              <option value="Entertainment">Entertainment</option>
              <option value="Environmental">Environmental</option>
              <option value="Finance">Finance</option>
              <option value="Food & Beverage">Food & Beverage</option>
              <option value="Government">Government</option>
              <option value="Healthcare">Healthcare</option>
              <option value="Hospitality">Hospitality</option>
              <option value="Insurance">Insurance</option>
              <option value="Machinery">Machinery</option>
              <option value="Manufacturing">Manufacturing</option>
              <option value="Media">Media</option>
              <option value="Not For Profit">Not For Profit</option>
              <option value="Other">Other</option>
              <option value="Recreation">Recreation</option>
              <option value="Retail">Retail</option>
              <option value="Shipping">Shipping</option>
              <option value="Technology">Technology</option>
              <option value="Telecommunications">Telecommunications</option>
              <option value="Transportation">Transportation</option>
              <option value="Utilities">Utilities</option>
            </select>
            {formdataerror.industry && (
              <h6 className="errormsg">Please Enter Industry</h6>
            )}
          </div>
          <div className="contactFormContent">
            <h2>Phone no.</h2>
            <div className="contactFormContentPhone">
              <select className="contactFormContentInput width20">
                <option value="">Select</option>
                {Countrycode.length !== 0
                  ? Countrycode.map((data, index) => (
                      <option value={data.dial_code} key={index}>
                        {data.name} - {data.dial_code}
                      </option>
                    ))
                  : null}
              </select>
              <input
                className="contactFormContentInput borderLeftNone"
                type="text"
                name="phone"
                onChange={handlechange}
                defaultValue={formdata.phone}
              />
            </div>
            {formdataerror.phone && (
              <h6 className="errormsg">Please Enter Phone no</h6>
            )}
          </div>
          <div className="contactFormContent">
            <h2>Message</h2>
            <textarea
              className="contactFormContentInput"
              rows="4"
              cols="50"
              name="message"
              onChange={handlechange}
              defaultValue={formdata.message}
            ></textarea>
            {formdataerror.message && (
              <h6 className="errormsg">Please Enter Message</h6>
            )}
          </div>
          <div className="ContactPrivacyPolicy">
            <input
              type="checkbox"
              onClick={() => {
                setcheckbox(!checkbox);
              }}
              checked={checkbox == true}
            />
            <p
              onClick={() => {
                setcheckbox(!checkbox);
              }}
            >
              You agree to our{" "}
              <a
                href="https://app.termly.io/policy-viewer/policy.html?policyUUID=ff795be0-1ecd-4ef8-b9ac-6ee47dc8b29a"
                target="_blank"
              >
                privacy policy.
              </a>
            </p>
          </div>
          {checkboxerror && (
            <h6 className="errormsg">Please Check privacy policy</h6>
          )}
          {successstatus && (
            <h6 className="successmsg">
              We have received your inquiry and will respond to you as soon as
              possible.
            </h6>
          )}
          {disable == true ? (
            <button className="contactSubmit" disabled>
              Please Wait...
            </button>
          ) : (
            <button className="contactSubmit" onClick={submitbtn}>
              Send Message
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
